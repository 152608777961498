.app{
  padding: 0;
  margin: 0;
}

/* PRIMARY SPAN*/
.primary{
  color: var(--primary-color);
  font-weight: 550;
}

/* ABOUT SECTION */
.about-main {
  padding: 30px 0px;
  margin-top: 40px;
}
.about-main h2{
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
}
.about-main h2:after {
  content: "";
  position: absolute;
  border-bottom: 2px solid var(--primary-color);
  bottom: 0px;
  height: 2px;
  width: 100px;
  left: 0px;
}
.about-main h5{
  font-size: 18px;
  font-weight: 600;
}

.about-main p{
  text-align: justify;
}

.about-main ul li {
  position: relative;
  font-weight: 300;
  list-style: none;
  line-height: 29px;
  font-size: 15px;
}

.about-main ul li span{
  padding: 1px 1px 1px 1px;
  color: var(--primary-color);
}

.about-main ul li .primary{
  font-weight: 600;
}

.about-main ul li .list-item{
  font-weight: 500;
  color: var(--text-color-light);
}
.list-item{
  font-weight: 500;
  color: var(--text-color-light);
}

p, li {
  padding-top: 10px;
  font-size: 16px;
  font-family: "Brown", Arial, sans-serif;
  font-style: normal;
  color: var(--text-color-light);
  padding-bottom: 0;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 940px) {
  .about-main h2{
    font-size: 28px;
  }
}

/* SERVICE SECTION*/
#service {
  text-align: center; /* Center content horizontally */
}

#service .title {
  display: inline-block; /* Allow underline to span text width */
  position: relative; /* Create space for underline below text */
  margin-bottom: 1rem; /* Add some space after the title */
  font-size: 38px;
  font-weight: 600;
  padding-bottom: 10px;
}

#service .title:after {
  content: ""; /* Empty content for underline */
  display: block;
  width: 100%; /* Underline spans full width of the title */
  height: 2px; /* Underline thickness */
  background-color: var(--primary-color); /* Set underline color (adjust as needed) */
  position: absolute;
  bottom: 0; /* Place underline below the text */
  left: 0; /* Align underline to the left of the text */
}

#service .subtitle {
  color: #000; /* Adjust subtitle color as needed */
  font-size: 1.5rem;
  font-weight: 600;
}

#service .btn {    
  padding: 10px 17px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: 600;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}
  
#service .btn:hover {
  color: var(--primary-color);
  background: #fff;
  border: 1px solid var(--primary-color);
}

/* Media Queries for responsiveness */
@media screen and (max-width: 940px) {
  #service .title{
    font-size: 28px;
  }
  #service .subtitle{
    font-size: 0.9rem;
  }
}

/* TEAM SECTION*/
#team {
  text-align: center; /* Center content horizontally */
  background: url(./assets/pexels-scottwebb-403575.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
  padding: 30px 0;
}



#team .title {
  display: inline-block; /* Allow underline to span text width */
  position: relative; /* Create space for underline below text */
  margin-bottom: 1rem; /* Add some space after the title */
  font-size: 38px;
  font-weight: 600;
  padding-bottom: 10px;
}

#team .title:after {
  content: ""; /* Empty content for underline */
  display: block;
  width: 100%; /* Underline spans full width of the title */
  height: 2px; /* Underline thickness */
  background-color: var(--primary-color); /* Set underline color (adjust as needed) */
  position: absolute;
  bottom: 0; /* Place underline below the text */
  left: 0; /* Align underline to the left of the text */
}

#team .subtitle {
  color: #000; /* Adjust subtitle color as needed */
  font-size: 1.5rem;
  font-weight: 600;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 940px) {
  #team .title{
    font-size: 28px;
  }
}

/* CONTACT SECTION*/
.contact-info{
  float: left;
  width: 100%;
}

.info-shape{
  background: var(--contact-form-color);
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  color: #fff;
  font-size: 22px;
  text-align: center;
  line-height: 50px;
  position: absolute;
}

.contant-info ul{
  list-style: none;
  padding: 0;
  float: left;
  width: 100%;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contant-info ul li div.info-content {
  padding-left: 65px;
}

.info-content h6{
  font-weight: 600;
}

.contact_form {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;
}

.contact_form .form-control {
  background-color: #fff;
  margin-bottom: 30px;
  border-right: none;
  border-left: none;
  border-top: 0;
  border-bottom: 2px solid #ebebeb;
  box-sizing: border-box;
  color: #666;
  font-size: 16px;
  outline: 0 none;
  padding: 10px 0px;
  height: 55px;
  resize: none;
  box-shadow: none !important;
  width: 100%;
  border-radius: 0;
}

input.form-control:focus{
  border-color: var(--contact-form-color);
}
textarea.form-control:focus{
  border-color: var(--contact-form-color);
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

.section-title h3 {
  font-weight: bold;
  color: #1f1f1f;
  text-transform: uppercase;
}

.map-main{
  margin-top: 5rem;
  border: 2px solid #646464;
  box-shadow: 10px 2px 5px var(--text-color-dark);
}

.contact{
  display: block;
  margin-top: 5rem;
  margin-bottom: 2rem;
  align-items: center;
}

.contact_form button {
  padding: 10px 17px;
  font-size: 1.1rem;
  color: var(--contact-form-color);
  font-weight: 600;
  background-color: white;
  border: 2px solid var(--contact-form-color);
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  width: 60%;
}
  
.contact_form button:hover {
  color: #fff;
  background: var(--contact-form-color);
  border: 1px solid var(--contact-form-color);
}


/* WORK SECTION*/
#work {
  text-align: center; /* Center content horizontally */
}

#work .title {
  display: inline-block; /* Allow underline to span text width */
  position: relative; /* Create space for underline below text */
  margin-bottom: 1rem; /* Add some space after the title */
  font-size: 38px;
  font-weight: 600;
  padding-bottom: 10px;
}

#work .title:after {
  content: ""; /* Empty content for underline */
  display: block;
  width: 100%; /* Underline spans full width of the title */
  height: 2px; /* Underline thickness */
  background-color: var(--primary-color); /* Set underline color (adjust as needed) */
  position: absolute;
  bottom: 0; /* Place underline below the text */
  left: 0; /* Align underline to the left of the text */
}

#work .subtitle {
  color: #000; /* Adjust subtitle color as needed */
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

#work h3{
  font-weight: 600;
  font-size: 30px;
  text-decoration: underline;
  text-align: start;
}
#work h4{
  font-weight: 600;
  font-size: 24px;
  text-align: start;
}

#work p{
  color: var(--text-color-light);
  text-align: justify;
}

#work th{
  color: var(--text-color-light);
  text-align: start;
}
#work td{
  color: var(--text-color-light);
  text-align: start;
}

#work img{
  border-radius: 5%;
  border: 2px solid #000;
}
#work img:hover{
  transition: .3s;
  transform: scale(1.03);
}
#work li{
  text-align: start;
}
#work ul{
  list-style-type: none;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 940px) {
  #work .title{
    font-size: 28px;
  }
  #work .subtitle{
    font-size: 0.9rem;
  }
  #work h3{
    font-size: 1.7rem;
  }
}