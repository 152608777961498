/* Overall f5oter styles */
#footer {
    background: url('../assets/footer-img.jpg');
    background-size: cover;
}

#footer-widgets {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Wrap widgets on smaller screens */
    margin-left: 6rem !important;
}

.widget {
flex: 1; /* Responsive sizing for widgets */
margin-bottom: 20px;
}

.navbar-brand img{
    width: auto;
    height: 5rem;
}

.widget-title {
    font-size: 1.25rem;
    padding-top: 10px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 600;
    font-family: inherit;
   
}

.widget-title::after{
    content: "";
    display: block; /* Make it visible */
    width: 100%; /* Match the width of the link */
    max-width: 60px;
    height: 3px; /* Set the underline height */
    background-color: var(--secondary-color); /* Underline color */
    margin-top: 5px; /* Add some space above the underline */
    transition: all 0.3s ease; /* Smooth transition on hover */
    left: 0;
  }

.textwidget, .widget_links {
  color: #fff;
}

.widget_links ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.widget_links li {
    margin-bottom: 5px;
}

.widget_links a {
    color: #fff;
    text-decoration: none;
}

.widget_links a:hover {
    color: var(--secondary-color);
    transition: .3s;
}

/* Contact information widget */
.widget-information ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
  
.widget-information li {
    margin-bottom: 10px;
}
  
.widget-information .hl {
    font-weight: bold;
    display: inline-block;
  
}
  
.widget-information span{
    padding: 1px 1px 1px 1px;
}
  
/* Copyright section */
#bottom {
    padding: 10px 0;
    text-align: center;
}
  
#copyright {
    color: #fff;
}
  
.clearfix{
    color: #fff;
}
  
.textwidget p{
    color: #fff;
}
  
#footer-widgets .widget.widget_socials .socials a {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 38px;
    color: #333;
    line-height: 38px;
    text-align: center;
    margin-right: 6px;
    margin-bottom: 10px;
    z-index: 1;
    background: #fff;
    border-radius: 100%;
    font-size: 17px;
    transition: 0.3s;
}
  
#footer-widgets .widget.widget_socials .socials a:hover {
    color: #fff;
    background: var(--secondary-color);
    transition: 0.3s;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 940px) {
    
    #footer{
        padding: 10px;
    }

    #footer-widgets {
        margin-left: 0 !important;
    }
    .navbar-brand img{
        height: 4rem;
    }

}



 