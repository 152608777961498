.header {
    position: fixed;
    height: 107px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    transition: 0.3s ease-in;
    background: #000;
    opacity: 0.5;
}

.header-bg {
    transition: 0.3s ease-in;
    background-color: #000;
    opacity: 1;
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

header .nav-menu {
    margin-top: 1rem;
}

.header .nav-menu a {
    color: #ffffff;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-size: 1.2rem;
    text-decoration: none;
}

.header .navbar img {
    width: 250px;
    height: auto;
  }
  
  .header .nav-menu {
    display: flex;
  }
  
  .header .nav-item {
    padding: 1rem;
    font-weight: 500;
  }
  
  .header .nav-item .nav-text:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
    -webkit-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    transition: all 400ms ease;
  }
  
  .hamburger {
    display: none;
  }
  
  /* Media Queries for responsiveness */
  @media screen and (max-width: 940px) {
    .header {
      max-width: 100%;
      background-color: rgba(0, 0, 0, 0.9);
    }
  
    .header .navbar {
      max-width: 100%;
    }
  
    .hamburger {
      display: block;
    }
  
    .nav-menu {
      position: fixed;
      left: -100%;
      top: 90px;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100%;
      height: 90vh;
      z-index: 999;
      text-align: center;
      transition: 0.3s;
    }
  
    .nav-menu.active {
      left: 0;
    }
  
    .nav-item {
      margin: 1.5rem 0;
    }
  
    .header .navbar img {
      width: 230px !important;
    }

    .dropdown-menu.show{
        width: 100%;
        left: auto;
        background-color: rgba(0, 0, 0, 0.9) !important;
    }

    .dropdown-menu.show a{
        text-align: center;
    }

    .nav-menu .dropdown-menu .dropdown-item:hover {
      padding-bottom: 0; /* Can keep padding for aesthetics */
      border-bottom: none !important; /* Can keep border for aesthetics */
      width: 7.5rem; /* Can keep width for aesthetics (optional) */
      margin-left: 42.5%; /* Can keep margin for aesthetics (optional) */
      /* Remove hover styles */
      transition: none !important; /* Disable transitions */
    }

    .nav-menu .dropdown-menu .dropdown-item{
      color: #fff !important;
      background-color: rgba(0, 0, 0, 0.9) !important;
    }

  }
  
.nav-item.active a {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.nav-item.active a::after {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.nav-menu .dropdown-menu .dropdown-item{
  color: #000;
  background-color: white;
}

.nav-menu .dropdown-menu .dropdown-item:hover {
  padding-bottom: 1px;
  border-bottom: 3px solid var(--secondary-color);
  width: 7.5rem;
  margin-left: 10%;
  -webkit-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
  