.touch-line{
	padding: 40px 0px;
	background: var(--secondary-dark);
	position: relative;
	margin-top: 90px;
}

.touch-line:before{
	position: absolute;
	content: "";
	z-index: -1;
	left: 0;
	top: -46px;
	bottom: auto;
	right: auto;
	z-index: -1;
	width: 100%;
	height: 50px;
	background-size: cover;
	background-position: 50% 50%;
}

.touch-line p{
	color: #ffffff;
	font-size: 16px;
  font-weight: 550;
}

.touch-line .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  width: 20rem;
  border-radius: .3rem;
}

.touch-line a.btn-secondary{
	background-color: #ffffff;
	border-color: #ffffff;
	color: var(--secondary-color);
  font-weight: 700;
}


.contactus-btn:hover {
	transition: transform .5s;
  
	&::after {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  transition: opacity 2s cubic-bezier(.165, .84, .44, 1);
	  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
	  content: '';
	  opacity: 0;
	  z-index: -1;
	}
  
	&:hover,
	&:focus {
	  transform: scale3d(1.006, 1.006, 1);
  
	  &::after {
		opacity: 1;
	  }
	}
}