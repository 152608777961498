body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #ffaa00;
  --primary-dark: #C05C1E;
  --secondary-color: #f3c74a;
  --secondary-dark:#e1922f;
  --contact-form-color:rgb(245, 149, 4);
  --my-white: #fff;
  --text-color-light: #262525;
  --text-color-dark: #000;
  --overlay: rgba(0,0,0,.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Brown", Arial, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
  padding: 0px;
  font-family: 'Work Sans', sans-serif;
  background: #fff;
}

/* scroll bar*/
body::-webkit-scrollbar { /* Fallback for older WebKit browsers */
  width: 2px; /* Adjust width as desired */
  height: 12px; /* Adjust height as desired */
}

body::scrollbar { /* Standard syntax for modern browsers */
  width: 2px; /* Adjust width as desired */
  height: 12px; /* Adjust height as desired */
}

body::-webkit-scrollbar-track { /* Fallback */
  background: #000; /* Adjust color */
}

body::scrollbar-track { /* Standard */
  background: #000; /* Adjust color */
}


body::-webkit-scrollbar-thumb { /* Fallback */
  background: var(--secondary-color); /* Adjust color */
  border-radius: 6px; /* Adjust roundness */
}

body::scrollbar-thumb { /* Standard */
  background: #A0A0A0; /* Adjust color */
  border-radius: 6px; /* Adjust roundness */
}





