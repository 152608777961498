.main {
    width: 100%;
    height: 95vh;
    background-color: #fff;
    box-shadow: 10px 0px 15px 5px #000;
    margin-bottom: 25px;
}

video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 13%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
    background-color: rgba(0,0,0,.4);
}

/* Media Queries for responsiveness */
@media screen and (max-width: 940px) {
    .content h1{
        font-size: 1.1rem !important;
    }
    .content span{
        font-size: .9rem !important;
    }
    video{
        width: 73vh !important; /* change here */
    }
    .overlay{
        width: 73vh; /* change here */
    }
}